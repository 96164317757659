<template>
  <div class="defaultTraffic">
    <div class="title">
      <h2>Default Report</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form v-model="defaultTraffic" :inline="true">
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Offer(s)</label>
                </el-col>
                <el-form-item label="OfferId(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                      v-model="defaultTraffic.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="offerChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
                <el-form-item label="AffiliateId(s)" label-width="2.08rem">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <group-select
                      v-model="defaultTraffic.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      :loading="affiliateLoading"
                      options
                      clearable
                      reserve-keyword
                      filterable
                      placeholder="请选择"
                      style="width:3.95rem;"
                    >
                    </group-select>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :lg="2" :md="2" :xs="24" :sm="2" :xl="2">
                  <label class="labelName">Aff_sub(s)</label>
                </el-col>
                <el-form-item label="Aff_Sub" label-width="1.64rem">
                  <el-col :lg="20" :md="20" :xs="24" :sm="20" :xl="20">
                    <el-input
                      v-model="defaultTraffic.affSubs"
                      style="width:11.7rem;"
                      placeholder="eg. carry_i or carry_i,carry_j"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="affSubsChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <label class="labelName" style="margin-right:20px;">TimeZone</label>
                <el-form-item>
                  <el-select v-model="defaultTraffic.timezone" style="width:420px;">
                    <el-option
                      v-for="item in timezoneOption"
                      :key="item.value"
                      :value="item.number"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :lg="2" :md="2" :xs="2" :sm="2" :xl="2">
                  <label class="labelName">Target(s)</label>
                </el-col>
                <el-form-item>
                  <el-col :lg="20" :md="20" :offset="2">
                    <el-select v-model="defaultTraffic.defType">
                      <el-option
                        v-for="item in targetOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="3" :xs="3" :sm="3" :xl="3">
                  <label class="labelName">Interval</label>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Month">
                    <el-checkbox v-model="monthChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Date">
                    <el-checkbox v-model="dateChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Hour">
                    <el-checkbox v-model="hourChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:60px;">Date</label>
                <el-form-item>
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:260px;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="20" :offset="1">
                  <el-form-item>
                    <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              v-loading="listLoading"
              style="width:100%;"
              height="70vmin"
              stripe
              border
              :data="defaultTrafficList"
              highlight-current-row
            >
              <el-table-column label="Date" prop="date"></el-table-column>
              <el-table-column label="Affiliate ID">
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                    scope.row.affiliateId
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Offer ID" v-if="offerChecked" key="offerId">
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                    scope.row.offerId
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Aff Sub"
                prop="affSub"
                v-if="affSubsChecked"
                key="affSub"
              ></el-table-column>
              <el-table-column label="Default Type" prop="defType"></el-table-column>
              <el-table-column label="Default Count" prop="count"></el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="pageCount"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageSize"
              :currentPage="currentPage"
            ></pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import { getDefaultTrafficList } from 'api/report';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';
  import dateUtils from '@/utils/dateutils.js';
  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import { mapActions, mapState } from 'vuex';
  import { filterObject } from 'utils/utils';

  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        pageSize: 20,
        currentPage: 1,
        offerChecked: false,
        collFlag: false,
        affSubsChecked: false,
        monthChecked: false,
        dateChecked: true,
        hourChecked: true,
        defaultTrafficList: [],
        pageCount: null,
        totalCount: null,
        startToEndDate: '',
        listLoading: false,
        targetOptions: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'BlackAff',
            value: 'BlackAff',
          },
          {
            label: 'BadGidIdfa',
            value: 'BadGidIdfa',
          },
          {
            label: 'PausedOffer',
            value: 'PausedOffer',
          },
          {
            label: 'BlackAffSub',
            value: 'BlackAffSub',
          },
          {
            label: 'WrongCountry',
            value: 'WrongCountry',
          },
        ],
        timezoneOption: optionData.timezoneOption,
        affiliateIdArray: [],
        defaultTraffic: {
          affiliateIds: null,
          offerIds: null,
          fromDate: null,
          toDate: null,
          timezone: 0,
          columns: [],
          defType: '',
        },
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);

      this.getAffiliateList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      getdefaultTrafficReportParam() {
        let param = {
          ...this.copy(this.defaultTraffic),
          page: this.currentPage,
          pageSize: this.pageSize,
        };

        if (this.offerChecked == true && param.columns.indexOf('offer_id') == -1) {
          param.columns.push('offer_id');
        } else if (this.offerChecked == false && param.columns.indexOf('offer_id') !== -1) {
          const offerIdIndex = param.columns.findIndex((item) => {
            if (item == 'offer_id') {
              return true;
            }
          });
          param.columns.splice(offerIdIndex, 1);
        }

        if (this.affSubsChecked == true && param.columns.indexOf('aff_sub') == -1) {
          param.columns.push('aff_sub');
        } else if (this.affSubsChecked == false && param.columns.indexOf('aff_sub') !== -1) {
          const affSubIndex = param.columns.findIndex((item) => {
            if (item == 'aff_sub') {
              return true;
            }
          });
          param.columns.splice(affSubIndex, 1);
        }

        if (this.monthChecked == true && param.columns.indexOf('month') == -1) {
          param.columns.push('month');
        } else if (this.monthChecked == false && param.columns.indexOf('month') !== -1) {
          const monthIndex = param.columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          param.columns.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && param.columns.indexOf('date') == -1) {
          param.columns.push('date');
        } else if (this.dateChecked == false && param.columns.indexOf('date') !== -1) {
          const dateIndex = param.columns.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          param.columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && param.columns.indexOf('hour') == -1) {
          param.columns.push('hour');
        } else if (this.hourChecked == false && param.columns.indexOf('hour') !== -1) {
          const hourIndex = param.columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          param.columns.splice(hourIndex, 1);
        }

        if (this.startToEndDate.length === 2) {
          let startDate = this.startToEndDate[0];
          let endDate = this.startToEndDate[1];
          param.fromDate = startDate;
          param.toDate = endDate;
        }

        if (this.defaultTraffic.defType == '') {
          this.defaultTraffic.defType = null;
        } else {
          param.defType = this.defaultTraffic.defType;
        }

        if (this.defaultTraffic.affiliateIds.length > 0) {
          param.affiliateIds = this.defaultTraffic.affiliateIds.join();
        }
        if (param.columns.length > 0) {
          param.columns = param.columns.join();
        }
        return param;
      },
      searchListClick(curPage) {
        this.listLoading = true;
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = filterObject(this.getdefaultTrafficReportParam());
        getDefaultTrafficList(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            this.defaultTrafficList = response.result;
            this.pageCount = response.totalPage;
            this.totalCount = response.total;

            let totalObject = new Object();
            if (this.monthChecked || this.dateChecked || this.hourChecked) {
              totalObject.date = 'Total';
            }

            totalObject.affiliate_id = '';
            if (this.offerChecked) {
              totalObject.offer_id = '';
            }
            if (this.affSubsChecked) {
              totalObject.aff_sub = '';
            }
            totalObject.def_type = '';
            totalObject.count = this.totalCount;
            this.defaultTrafficList.push(totalObject);
          } else {
            this.$message.error(response.status.msg);
          }
        });
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.searchListClick();
      },
      exportExcelFileClick() {},
      affiliatetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/affiliate/detail',
          query: {
            affiliateId: row.affiliate_id,
          },
        });
        window.open(href, '_blank');
      },
      offerdetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/offer/detail',
          query: {
            offerId: row.offer_id,
          },
        });
        window.open(href, '_blank');
      },
    },
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
